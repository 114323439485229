// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open%20Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800&amp;subset=latin,latin-ext);
// Variables
@import "variables";
// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// General tags
html {
  width: 100%;
  height: 100%;
  min-height: 100%;
  font-family: $font-family-sans-serif;
}

.btn-primary {
  a {
    color: white;
    text-decoration: none;
  }
}

body {
  width: 100%;
  min-height: 100%;
  position: relative;
  color: $text-color;
}

.vue-app {
  height: 100%;
  width: 100%;
}

@media (max-width: 991px) {

  .content-page {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 880px;
  }
}

@media (min-width: 991px) {
  .content-page {
    height: 100%;
    padding-top: 80px;
    padding-bottom: 590px;
  }
}

h2 {
  color: $brand-primary;
}

p {
  text-align: justify;
  display: block;
}

.ml-container {
  max-width: 1020px;
  height: 100%;
  margin: auto;
}

.rounded {
  border-radius: 10px;
}

.ml-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/images/css/rtl-header-one.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.3;
  -webkit-transition: height 100ms ease; /* Safari */
  transition: height 100ms ease;
}

.transitive-container {
  -webkit-transition: max-height 300ms ease, width 300ms ease !important; /* Safari */
  transition: max-height 300ms ease, width 300ms ease !important;
  max-height: 445000px !important;
}

.no-a-behaviour {
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

// Navbar styles
.hidden {
  display: none;
}

.shown {
  display: inherit;
}

.ml-navbar {
  position: fixed;
  font-size: small;
  font-weight: 600;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1030;
  background-color: $brand-white;
  border-bottom: 5px solid;
  border-bottom-color: $brand-primary;
  height: 80px;
  box-shadow: 0px 5px 10px #888888;
}

.ml-navbar-contact-details {
  padding: 10px 5px 10px 5px;
  margin: 0;
}

.ml-navbar-logo {
  text-align: center;
  height: 100%;
  font: 0/0 a;
}

.ml-navbar-logo-img {
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}

//smaller
@media (max-width: 1015px) {

  .ml-navbar-menu {
    height: 100%;
    .menu-icon-holder {
      text-align: right;
      height: 100%;
      display: inline-block;
      width: 100%;
      padding-right: 15px;
      .glyphicon {
        padding: 8px;
        border-radius: 5px;
        background-color: $brand-highlight;
        font-size: x-large;
      }
    }
    ul {
      list-style-type: none;

      a {
        color: $text-color;
        text-decoration: none;
        li {
          vertical-align: middle;
          padding: 5px;
          cursor: pointer;
          border-radius: 5px;
          -webkit-transition: 300ms; /* Safari */
          transition: 300ms;

          span {
            vertical-align: middle;
            display: inline-block;
            color: $text-color;
            padding-left: 2px;
            padding-bottom: 2px;
          }

        }

        li:hover {
          background-color: $brand-highlight;
        }
      }
      .vertical-aligner {
        display: none;
      }
    }

  }
  .navbar-menu {
    display: none;
    overflow-y: scroll;
    width: 100%;
    background-color: $brand-white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 0px 0px 5px 5px;
    -webkit-transition: 300ms; /* Safari */
    transition: 300ms;
    position: fixed;
    left: 0;
    top: 75px;
    max-height: calc(100vh - 75px);
    padding: 5px;

  }
  .nav-dropdown {
  }

  .nav-dropdown-content {
    display: none;
    background-color: $brand-white;
    text-align: left;
    width: 100%;
    padding: 0px 0px;
    z-index: 1;
    -webkit-transition: ease 300ms; /* Safari */
    transition: ease 300ms;
  }

  .nav-dropdown-content {
    ul {
      list-style-type: none;
      padding: 5px;
      width: 100%;
      .dropdown-li-item {
        width: 100%;
        //padding: 5px 5px;
      }
      .dropdown-li-item:hover {
        background-color: $brand-highlight;
      }
    }
    border-radius: 5px;
    overflow-y: scroll;
    -webkit-transition: 300ms; /* Safari */
    transition: 300ms;
  }

  .menu-shown {
    display: block;
    -webkit-transition: 300ms; /* Safari */
    transition: 300ms;
  }
}

//bigger
@media (min-width: 1016px) {

  .ml-navbar-menu {
    padding: 0;
    .menu-icon-holder {
      display: none;
    }
    ul {
      list-style-type: none;

      height: 100%;
      vertical-align: middle;
      padding: 0;
      display: inline-block;
      a {
        color: $text-color;
        text-decoration: none;
        li {
          vertical-align: middle;
          padding: 5px;
          display: inline-block;
          cursor: pointer;
          border-radius: 5px;
          -webkit-transition: 300ms; /* Safari */
          transition: 300ms;

          span {
            vertical-align: middle;
            display: inline-block;
            color: $text-color;
            padding-left: 2px;
            padding-bottom: 1px;
          }

        }

        li:hover {
          background-color: $brand-highlight;
        }
      }
    }
    .navbar-menu {
      height: 100%;
      width: 100%;
      display: inline-block !important;

    }

    .nav-dropdown {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 5px;
      cursor: pointer;
      border-radius: 5px;
      -webkit-transition: 300ms; /* Safari */
      transition: 300ms;

      span {
        vertical-align: middle;
        display: inline-block;
        color: $text-color;
        padding-left: 2px;
        padding-bottom: 1px;
      }
    }

    .nav-dropdown:hover {
      background-color: $brand-highlight;
    }

    .nav-dropdown-content {
      display: none;
      position: absolute;
      background-color: $brand-white;
      text-align: left;
      min-width: 150px;
      left: 0px;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      padding: 0px 0px;
      z-index: 1;
      -webkit-transition: 300ms; /* Safari */
      transition: 300ms;
    }

    .nav-dropdown:hover .nav-dropdown-content {
      display: block;
    }

    .nav-dropdown-content {
      ul {
        list-style-type: none;
        padding: 5px;
        width: 100%;
        .dropdown-li-item {
          width: 100%;
          padding: 5px 5px;
        }
        .dropdown-li-item:hover {
          background-color: $brand-highlight;
        }
      }

      .col-xs-6 {
        padding: 0 5px;
      }

      border-radius: 5px 5px 5px 5px;
      border: 3px solid $brand-highlight;
      overflow: hidden;
    }

    .double-column {
      min-width: 400px;
    }

    .dropdown-column {
      display: inline-block;
    }
    height: 100%;
  }

}

//Footer styles

.ml-footer {

  .ml-footer-inner {
    padding: 15px;
  }

  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 300px;
  overflow: hidden;

  h4, h3 {
    text-align: left;
    color: #f2f2f2;
  }

  p {
    color: #f2f2f2;
    text-align: left;
  }
  //background-color: $brand-secondary;
  background-color: #525252;

}

//footer {
//  background-color: #525252;
//  p {
//    color: #f2f2f2;
//    text-align: left;
//  }
//  h2, h3 {
//    color: #f2f2f2;
//    text-align: left;
//    font-size: 2em;
//  }
//  position: absolute;
//  left: 0;
//  height: 350px;
//  width: 100%;
//  overflow: hidden;
//  @media screen and (max-width: 769px) {
//    height: 500px;
//
//  }
//}

//Padding for bootstrap elements

.nopadding {
  padding: 0;
}

.nomargin {
  margin: 0;
}

//Background classes for sections

.background-primary {
  background-color: $brand-primary;
}

.background-secondary {
  background-color: $brand-secondary;
}

.background-highlight {
  background-color: $brand-highlight;
}

.background-lightgray {
  background-color: rgb(236, 236, 236);
}

//Components holder

.component {
  max-width: 100%;
  padding: 8px;
}

.penalty-calc-component {
  overflow: hidden;
  border: 1px solid $brand-primary;

  .component-heading {
    margin-top: 0;
    border-radius: 0;
  }

}

//Forms

.form-control {
  width: 100%;
  font-size: 16px;
}

.form-column {
  padding-bottom: 3px;
  padding-top: 3px;
}

.btn-secondary {
  background-color: $brand-primary;
  color: $text-color;
  transition: 300ms ease;
  -webkit-transition: 300ms ease;

  &:hover {
    background-color: $brand-primary-darker;
  }
}

.btn-lightblue {
  background-color: #bce8f1;
  color: $text-color;
  transition: 300ms ease;
  -webkit-transition: 300ms ease;

  &:hover {
    background-color: #a2cdd6;
  }
}

.btn-form {
  margin: 10px;
  font-weight: bold;
}

.hider {
  transition: 300ms ease;
  -webkit-transition: 300ms ease;
}

.btn-inactive.btn-inactive {
  pointer-events: none;
  cursor: wait;
}

.red-text {
  transition: 300ms ease;
  -webkit-transition: 300ms ease;
  color: red;
}

.success-message {
  transition: 300ms ease;
  -webkit-transition: 300ms ease;
  width: 100%;
  text-align: center;
  background-color: rgba(141, 237, 141, 0.55);
  font-weight: bold;
  border-radius: 5px;
  padding: 10px;
}

//Headings

.home-heading {
  opacity: 1;
  z-index: 0;
  text-align: center;
  position: relative;
  color: $brand-primary;
  background-color: $text-color;
  margin: 0 5px;
  padding: 15px;
  border-radius: 15px;

}

.offence-collapsible {
  .panel-heading {
    .panel-title {
      color: $text-color;
    }
    transition: 200ms ease;
    -webkit-transition: 200ms ease;

    &:hover {
      background-color: $text-color;
      .panel-title {
        color: $brand-primary;
      }
      transition: 200ms ease;
      -webkit-transition: 200ms ease;
    }
  }

  img {
    max-width: 100%;
  }
}

.case-links {
  button {
    margin: 10px 0;
  }
}

#faq {
  .panel-body {
    text-align: justify;
  }
}

.faq-title-text {
  display: inline-block;
  max-width: 90%;
}

.home-heading-container {
  text-align: center;
}

.component-heading {
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 10px 0;
  text-align: center;
  background-color: $brand-primary;
  border-radius: 10px;
  font-size: larger;
  font-weight: bold;
  color: $text-color
}

.section-heading {
  font-size: 26px;
  padding: 15px 0 15px 0;
  color: $text-color;
  text-align: center;
}

.section-heading-home {
  margin-bottom: 40px;
}

.page-heading {
  margin-top: 40px;
}

.short-intro {
  font-size: large;
  font-weight: 400;

  padding: 20px;
  text-align: justify;

  .homepage-intro {
    font-size: medium;
    strong {
      font-size: large;
    }
  }

  .btn-track-record {
    font-size: medium;
  }

  strong {
    font-size: large;
  }
}

section {
  padding: 15px 0;
}

//Personal cards

.personal-card {
  border: 1px solid darkgray;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 10px;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 5px 10px #848484;
    margin: 0;
  }

  img {
    max-width: 100%;
    margin: auto;
    border-radius: 5px 5px 0px 0px;
  }

  .images-container {
    position: relative;
  }

  .card-description {
    padding: 5px 8px;
    max-height: 100px;
    overflow: hidden;
    background-color: white;
    font-weight: 400;
    box-shadow: inset 0 -15px 30px -10px #000000;
  }

  .card-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: large;
    font-weight: bold;
    padding: 8px;
    background-color: whitesmoke;
    opacity: 0.8;
    z-index: 3;
  }

  a, a:hover {
    text-decoration: none;
  }

  .card-btn {
    color: $text-color;
    font-weight: bold;
    font-size: medium;
    text-align: center;
    padding: 8px;
    text-decoration: none;
  }
}

//Contact form

.contact-form {
  background-color: $brand-primary;
  border-radius: 10px;
  box-shadow: 0 0 10px #000000;
  max-width: 480px;
  margin: 10px auto;

  .btn-secondary {
    background-color: $text-color;
    color: $brand-primary;
    transition: 300ms ease;
    -webkit-transition: 300ms ease;

    &:hover {
      background-color: #6e6e6e;
    }
  }
}

.contact-form-heading {
  padding: 5px;
  font-size: 20px;
  font-weight: bold;

  div {
    padding: 3px;
    border-radius: 5px;
    background-color: white;
  }

  img {
    max-height: 45px;
  }
}

//Links tiles

.link-tile {
  width: 100%;
  height: 100px;
  background-color: white;
  color: $text-color;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  box-shadow: 0 0 10px #cfcfcf;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  cursor: pointer;
  .icon-holder {
    display: inline-block;
    padding: 5px;
    padding-top: 10px;
    vertical-align: middle;
    img {
      transition: 200ms ease;
      -webkit-transition: 200ms ease;
    }
  }
  .link-description {
    padding: 5px;
    vertical-align: middle;
    font-size: 15px;
    text-align: center;
    font-weight: 400;

    .tile-link-name {
      padding-bottom: 5px;
      font-size: medium;
      font-weight: bold;
    }
  }

}

.link-tile:hover {

  box-shadow: 0 0 10px #efcd2f;

  color: $brand-primary-darker;

  a {
    color: $brand-secondary;
  }

}

//Offences tiles

.offence-tile {
  width: 100%;
  height: 325px;
  background-color: white;
  color: $text-color;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  padding-top: 5px;
  box-shadow: 0 0 10px #cfcfcf;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  cursor: pointer;
  .icon-holder {
    padding: 5px;
    padding-top: 10px;
    vertical-align: middle;
    i {
      color: $brand-primary;
      transition: 200ms ease;
      -webkit-transition: 200ms ease;
    }
  }
  .tile-description {
    padding: 1px;
    vertical-align: middle;
    font-size: 15px;
    text-align: center;
    font-weight: 400;

    .tile-offence-name {
      padding-bottom: 5px;
      font-size: medium;
      font-weight: bold;
      h3 {
        margin-top: 11px;
      }
    }
  }
}

#offence-tiles {
  a {
    text-decoration: none;
  }
}

.offence-tile:hover {
  background-color: $brand-primary;
  color: white;

  i {
    color: white;
  }

}

// Court Process

.court-process-tile {
  width: 100%;
  height: 120px;
  background-color: white;
  color: $text-color;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  padding-top: 5px;
  box-shadow: 0 0 10px #cfcfcf;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  cursor: pointer;
  .icon-holder {
    padding: 5px;
    padding-top: 10px;
    vertical-align: middle;
    i {
      color: $brand-primary;
      transition: 200ms ease;
      -webkit-transition: 200ms ease;
    }
  }
  .tile-description {
    padding: 1px;
    vertical-align: middle;
    font-size: 15px;
    text-align: center;
    font-weight: 400;

    .tile-court-process-name {
      padding-bottom: 5px;
      font-size: medium;
      font-weight: bold;
      h3 {
        margin-top: 11px;
      }
    }
  }
}

#court-process-tiles {
  a {
    text-decoration: none;
  }
}

.court-process-tile:hover {
  background-color: $brand-primary;
  color: white;

  i {
    color: white;
  }

}
//Call us now

.call-us-now-holder {
  width: calc(100% - 20px);
  height: calc(100% - 30px);
  border-radius: 8px;
  text-align: center;
  margin: 20px 10px 10px 10px;
  background-image: url('/images/css/office.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}

.call-us-now-button {
  font-size: large;
  font-weight: bold;
  background-color: $brand-primary;
  color: $text-color;
  box-shadow: 0 0 20px #000000;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;

  &:hover {
    box-shadow: 0 0 20px #ffffff;
    background-color: $brand-primary-darker;
  }
}

.hover-enlarged {
  transition: 150ms ease;
  -webkit-transition: 150ms ease;

  &:hover {
    font-size: larger;
  }
}

.award-tile {
  font-size: 1.5em;
}

.award-badge {
  max-height: 200px;
  margin: auto;
}

.offence-title {
  border-radius: 10px;
  background-color: $text-color;
  padding: 15px;
  margin: 30px 0;
  color: $brand-primary;

  h2 {
    margin: 0;
  }

  h1 {
    margin: 0;
  }
}

.offence-intro {
  margin: 15px 0;
  text-align: justify;
  padding: 10px;
  font-size: medium;
}

//About us

.about-us-card {
  //border: 1px solid black;
  //border-radius: 10px;
  padding-bottom: 15px;
  border-bottom: 2px $brand-primary solid;
}

.about-us-description {

}

.about-us-image {
  padding-top: 22px;
  text-align: center;
  img {
    border-radius: 10px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }
}

//Alt home page

.banner-container {
  position: relative;
  background: #303843;
  overflow: hidden;

}

.banner-container:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
  background-image: url('images/css/rtl-header-high.jpg');
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.ml-banner {
  width: 100%;
  padding-top: 115px;
  min-height: 240px;
  margin: 0;
}

.carousel-holder {
  border-radius: 10px;

  slide {
    display: inline-block;
    vertical-align: middle;
  }
}

.carousel-img-holder {
  width: 100%;
  text-align: center;
  height: 100%;
  padding: 15px;
}

.carousel-card-holder {
  position: relative;
  box-shadow: 0 0 25px #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.carousel-img {
  margin: auto;
  vertical-align: middle;
  max-width: 100%;
}

.carousel-text {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: $text-color;
  padding: 5px;
  font-style: italic;
  font-weight: bold;
  font-size: medium;
}

.carousel-details {
  font-weight: 200;
  text-align: right;
  border-radius: 5px;
  padding: 2px;
  color: $text-color;
}

.carousel-name {
  font-size: medium;
}

.carousel-position {
  font-size: small;
  font-style: italic;
}

.carousel-3d-img {
  max-height: 100% !important;
  max-width: 100% !important;
  width: auto !important;
  box-shadow: 0 0 25px #ffffff;
  display: none;
  margin: auto;
}

.img-shown {
  display: block !important;
}

.img-hidden {
  display: none;
}

.carousel-3d-slide {
  text-align: center !important;
  background-color: transparent !important;
  border: none !important;
  border-radius: 8px !important;

}

.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  font-size: 15px;
  line-height: 17px;
  min-width: 100%;
  box-sizing: border-box;

  .carousel-3d-person {
    color: $text-color;
  }

  .carousel-3d-quote {
    color: white;
  }
}

.carousel-3d-controls {

  //position: inherit !important;
  height: auto !important;
  margin: 0 !important;

  .prev, .next {

    @media (min-width: 1015px) {

      margin-left: 200px;
      margin-right: 200px;

    }
    line-height: 30px !important;
    font-size: 100px !important;
    color: $brand-primary !important;
    height: auto !important;
    top: 20% !important;
  }
}

.carousel-3d-text {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px #cfcfcf;
  font-size: medium;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  overflow: hidden;
}

.carousel-3d-quote {
  padding: 8px;
  text-align: left;
  font-weight: 550;
  font-style: italic;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
}

.carousel-3d-person {
  padding: 8px;
  background-color: $brand-primary;
  font-weight: bold;
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
}

.carousel-3d-position {
  font-style: italic;
  font-weight: 400;
}

.photo-attribution{
  margin-top: 5px;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;

  a {
    text-decoration: underline;
    color: black;

    :hover{
      color: $brand-primary;
    }
  }
}

//Vertical alignment

.vertical-aligner {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  font-size: 0/0 a;
}

.vertical-alignee {
  display: inline-block;
  vertical-align: middle;
}

.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

// TRANSITIONS
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter, .fade-leave-active {
  opacity: 0;
}

//Icons hover colors

.fa-google-plus-official {
  padding-right: 5px;
  &:hover {
    transition: 200ms ease;
    -webkit-transition: 200ms ease;
    color: #db4437;
    cursor: pointer;
  }
}

.fa-twitter:hover {
  transition: 200ms ease;
  -webkit-transition: 200ms ease;

  color: #1da1f2;
  cursor: pointer;
}

.facebook-icon {
  padding-right: 4px;
  border-radius: 5px;
}

.facebook-icon:hover {
  transition: 200ms ease;
  -webkit-transition: 200ms ease;
  background-color: #145e91;
  color: white;
  cursor: pointer;
}

.text-color {
  color: $text-color;
}

.heading {
  margin: 20px;
}

// Google reviews

#reviews {
  padding: 20px 0;
}

.reviews-img-holder {
  max-width: 100%;
  padding: 15px;

  img {
    max-width: 100%;
    border-radius: 10px;
  }
}

.leave-a-review {
  padding: 10px;
  font-weight: bold;
}

@media (min-width: 1015px) {

  .reviews-item {
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px $text-color;
    max-height: 300px;
    webkit-transition: max-height 400ms;
    -moz-transition: max-height 400ms;
    -ms-transition: max-height 400ms;
    -o-transition: max-height 400ms;
    transition: max-height 400ms;

    &:hover {
      max-height: 700px;
      webkit-transition: max-height 400ms;
      -moz-transition: max-height 400ms;
      -ms-transition: max-height 400ms;
      -o-transition: max-height 400ms;
      transition: max-height 400ms;
    }
  }
}

.reviews__review-er {
  padding: 10px;
  font-size: medium;
  background-color: $brand-primary;
  font-weight: bold;
  i {
    margin: 0 8px 0 2px;
  }
}

#results-dumfries {
  .reviews__review-er {
    background-color: #bce8f1;
  }
}

@media (max-width: 1015px) {
  .reviews-item {
    position: relative;
    overflow: hidden;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 0 10px $text-color;
  }
}

.reviews-item-static {
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 0 10px $text-color;
}

.reviews__review-date {
  padding: 5px 10px;
  background-color: $brand-highlight2;
}

.reviews__review-rating {
  position: absolute;
  display: inline-block;
  padding: 5px;
  font-size: 1em;
  top: 0;
  right: 0;
  color: $text-color;
}

.google-plus-logo {
  font-size: 20px;

  img {
    height: 50px;
  }
}

.reviews__review-rating--1 {
  content: '\f005';
}

.reviews__review-rating--2 {
  content: '\f005 \f005';
}

.reviews__review-rating--3 {
  content: '\f005 \f005 \f005';
}

.reviews__review-rating--4 {
  content: '\f005 \f005 \f005 \f005';
}

.reviews__review-rating--5 {
  content: '\f005 \f005 \f005 \f005 \f005';
}

.reviews__review-comment {
  padding: 10px;
  text-align: justify;
  color: $text-color;
}

.trustpilot-logo {
  margin: 15px 0;
  text-align: center;
  padding: 10px;

  img {
    max-width: 100%;
  }
}

//LICENCE CHECKER

.licence-img-holder {
  padding: 5px;
  max-width: 100%;
  margin: 20px 0;

  img {
    max-width: 100%;
  }
}

.licence-button-holder {
  margin: 10px 0;
  padding: 10px 0;
}

//Track record

.search-btn {
  text-align: center;
  padding-top: 26px;
  margin-bottom: 20px;
}

.btn-track-record {
  font-size: medium;
  font-weight: bold;
  margin-top: 15px;
  background-color: $brand-primary;
  color: $text-color;
  webkit-transition: 200ms;
  -moz-transition: 200ms;
  -ms-transition: 200ms;
  -o-transition: 200ms;
  transition: 200ms;

  &:hover {
    background-color: $text-color;
    color: $brand-primary;
    webkit-transition: 200ms;
    -moz-transition: 200ms;
    -ms-transition: 200ms;
    -o-transition: 200ms;
    transition: 200ms;
  }
}

.search-results {
  margin-top: 20px;

}

.search-result-item {
  overflow: hidden;
  margin: 15px 0;
  max-width: 100%;
  border: 1px solid $text-color;
  box-shadow: -5px 0 7px #4c4c4c;
  border-radius: 10px;
  height: 100%;
  webkit-transition: margin 200ms;
  -moz-transition: margin 200ms;
  -ms-transition: margin 200ms;
  -o-transition: margin 200ms;
  transition: margin 200ms;

  @media (min-width: 1015px) {
    &:hover {
      margin-left: 18px !important;
      margin-right: 12px !important;
      webkit-transition: margin 200ms;
      -moz-transition: margin 200ms;
      -ms-transition: margin 200ms;
      -o-transition: margin 200ms;
      transition: margin 200ms;
    }
  }
}

.search-panel {
  select {
    margin: 5px 0;
  }
}

.search-case-title {
  background-color: $brand-primary;
  font-weight: bold;
  font-size: medium;
}

.search-case-date, .search-case-offence, .search-case-court, .search-case-content, .search-case-title {
  padding-left: 5px;
}

.search-case-title {
  padding: 5px;
}

.search-case-image {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.search-case-content {
  font-style: italic;
  padding-bottom: 5px;
}

.search-pages {
  text-align: center;
}

.case-badge {
  position: absolute;
  top: 55px;
  left: -90px;
  background-color: rgba(0, 0, 0, 0.66);
  color: $brand-primary;
  font-weight: bold;
  font-size: medium;
  padding: 5px;
  width: 300px;
  text-align: center;
  -ms-transform: rotate(-45deg); /* IE 9 */
  -webkit-transform: rotate(-45deg); /* Chrome, Safari, Opera */
  transform: rotate(-45deg);
}

//############# BLOG ###################/

.post-content {
  text-align: left;

  .post-main-image {
    text-align: -webkit-center;
    padding: 10px;
  }

  .content {
    clear: both;
    padding-top: 50px;

    img{
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  h2 {
    color: black !important;
  }
}

// RECENT POSTS

.recent-posts {

  padding-top: 20px;
  .recent-header {
    font-weight: bold;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-top-left-radius: 5px;
  }

  .recent-body {

    .recent-title-image {
      text-align: center;
    }
    img {
      width: 80%;
    }
  }
}

.post-item {
  box-shadow: 0 0 10px #4c4c4c;
  margin-bottom: 25px;
  height: 450px;

  .card-btn {
    height: 10%;
    text-align: center;
    font-weight: 500;
    color: $text-color
  }
}

.blog-image-container {
  height: 50%;
  text-align: center;
  overflow: hidden;
  img {
    min-height: 100%;
    min-width: 100%;
  }
}

.post-description {
  height: 40%;
  //border-top: 1px solid $text-color;
  padding: 5px;
  font-weight: 400;
  overflow: hidden;
  //box-shadow: inset 0 -15px 30px -10px #000000;
}

textarea {
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  button {
    a {
      color: white;
    }
  }
}

//Office maps

.office-maps {
  margin: 15px 0;
  padding: 10px;
}

.office-holder {
  margin: 25px 0;
}

.office-image-holder {
  width: 60%;

  img {
    max-width: 100%;
    border-radius: 10px;
  }
}

.office-info {
  border-left: 1px solid $text-color;
  margin: 10px 0;
  padding: 10px;
}

.office-title {
  font-size: medium;
  font-weight: bold;
}

.office-address {

}

.office-tel {

}

.office-map {
  width: 100%;
  height: 300px;
}

.dumfries-details {
  font-size: 1.5em;

}

// CASES
.content {
  .outcome {
    color: $text-color;
  }
}

.brand-line {
  height: 4px;
  border-radius: 4px;
  background-color: $brand-primary;
  width: 75%;
  margin: 15px auto;
}

.case-in-offence {
  padding: 10px;
  margin: 10px;
  box-shadow: -5px 0px 7px #666666;
  border-radius: 10px;
}

#cases {
  margin: 30px 0;
  border-radius: 10px;
  border: 2px solid $brand-primary;
  text-align: center;

  p {
    text-align: center;
  }

  .btn {
    color: $text-color;
    margin-bottom: 10px;
    font-size: medium;
    font-weight: 500;
    padding: 10px 30px;

    &:hover {
      color: $brand-primary;
      background-color: $text-color;
    }
  }

}

.case-img-holder {
  position: relative;
  margin: auto;
  width: auto;
  display: inline-block;
  overflow: hidden;
}

@media (max-width: 430px) {
  .case-img-holder {
    max-width: 100%;
  }

  .post-main-image {
    text-align: -webkit-center;
    padding: 10px;

    img {
      max-width: 100%;
    }
  }
}

@media (min-width: 430px) {
  .case-img-holder {
    max-width: 400px;
  }

  .post-main-image {
    text-align: -webkit-center;
    padding: 10px;

    img {
      max-width: 400px;
    }
  }

}




.case-links {
  padding-top: 20px;
  button {
    width: 150px;
  }
}

.see-also-heading {
  color: black;
}

.see-also-box {
  padding: 2px 15px;
  border-radius: 8px;
  border: 1px solid #a8a8a8;
}

//Locations styles

.location-main-image{
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.location-title{
  margin-bottom: 50px;

  span{
    padding-bottom: 4px;
    font-size: 90%;
    border-bottom: 3px solid $brand-primary
  }
}
@media (min-width: 768px) {

  .location-courts {
    padding-left: 15px;
    border-left: 3px solid $brand-primary;
  }

}

.courts-we-serve {
  margin: 20px 0 30px 0;
}

.location-card {
  display: block;
  width: 90%;
  margin: 10px auto;
  text-align: center;
  padding: 20px;
  font-size: 120%;
  color: black;
  border: 3px solid $brand-primary;
  background-color: transparent;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: 0 0 30px -3px #000000;
  -webkit-transition: 300ms; /* Safari */
  transition: 300ms;

  &:hover{
    text-decoration: none;
    -webkit-transition: 300ms; /* Safari */
    background-color: #484848;
    color: whitesmoke;
    transition: 300ms;
  }
}

.courts-image{
  border-radius: 5px;
  margin: auto;
}

.courts-image-holder{
  padding: 5px;
}

#address {
  h3 {
    margin: 5px;
  }

  p {
    margin: 5px;
  }
}

.location-court{
  padding: 10px;
}

.location-contact-us{
  margin-top: 30px;
}

@media (max-width: 768px) {
  .location-title{

    span{
      font-size: 70%;
    }
  }
}

.location-court-name{
  margin-bottom: 15px;
}

.court-locations{
  margin-bottom: 40px;
}

.location-content{
  font-size: medium;
  padding:  0 30px 0 30px;
}

.in-location{
  padding: 12px;
  box-shadow: 0 10px 30px -10px #000000;
  border-radius: 8px;
  -webkit-transition: 300ms; /* Safari */
  transition: 300ms;

  &:hover {
    box-shadow: none;
    -webkit-transition: 300ms; /* Safari */
    transition: 300ms;
  }

}

.case-icon{
  color: $brand-primary;
}


