
// Body
$body-bg: #ffffff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #ffc313;
$brand-secondary: #000000;
$brand-white: #ffffff;
$brand-link: #ca9113;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
$brand-highlight: #e6e6e6;
$brand-highlight2: #ececec;
$brand-primary-darker: #ffdb35;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #373a3d;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
